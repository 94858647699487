export default {
    title: 'dark',
    icon: 'light_mode',
    colors: {
        background: '#161616',
        text: '#e8e8e8',
        link: '#ffd600',
        hover: {
            link: '#0aa9da'
        }
    },

}

