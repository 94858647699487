export default {
    title: 'light',
    icon: 'dark_mode',
    colors: {
        background: '#f5f5f5',
        text: '#333',
        link: '#333',
        hover: {
            link: '#0aa9da'
        }
    },

}
